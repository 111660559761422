import React, { FormEvent, useMemo, useRef, useState } from 'react'

import { CheckboxSelect, Icon, Input, SelectOptionType, VStack } from '@revolut/ui-kit'
import { validateClickupTask } from '@src/api/roadmaps'
import { debounce, uniqBy } from 'lodash'
import { string } from 'zod'
import { ClickUpSelectOption } from './components/ClickupSelectOption'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { EpicOption, KpiInterface } from '@src/interfaces/kpis'
import { ClickupSelectedTaskWidget } from './components/ClickupSelectedTaskWidget'

// user can insert either task ID or task url from clickup
const taskIdValidator = string()
  .url()
  // next value after /t/ should be the task ID
  .transform(val => val.split(`/t/`)?.[1]?.split(/(\?|\/)/)[0])
  .or(string())

type ClickupOption = { key: string; name: string; url: string }

export const ClickupTasks = () => {
  const inputRef = useRef(null)
  const [searchPending, setSearchPending] = useState(false)
  const [searchValue, setSearchValue] = useState<string>()
  const { values } = useLapeContext<KpiInterface>()
  const [options, setOptions] = useState<ClickupOption[]>([])
  const [errorMessage, setErrorMessage] = useState<string>()

  const debouncedSearch = useMemo(() => {
    setErrorMessage(undefined)
    return debounce(async (searchString: string) => {
      try {
        const itemId = taskIdValidator.parse(searchString)
        const {
          data: { exists, data },
        } = await validateClickupTask({ itemId })
        if (exists && data) {
          setOptions([{ key: data.id, name: data.name, url: data.url }])
        }
      } catch (e) {
        setErrorMessage(getStringMessageFromError(e))
      } finally {
        setSearchPending(false)
      }
    }, 1000)
  }, [])

  const onSearch = (searchString?: string) => {
    if (searchString === searchValue) {
      return
    }
    setOptions([])
    setSearchValue(searchString)
    if (searchString) {
      setSearchPending(true)
      debouncedSearch(searchString)
    }
  }

  return (
    <VStack space="s-16">
      <Input
        invalid={!!errorMessage}
        errorMessage={errorMessage}
        containerRef={inputRef}
        placeholder="Search tasks by ID or url"
        pending={searchPending}
        onChange={(ev: FormEvent<HTMLInputElement>) => onSearch(ev?.currentTarget?.value)}
        renderAction={() => (searchPending ? undefined : <Icon name="Search" />)}
        value={searchValue}
      />
      <CheckboxSelect<ClickupOption, SelectOptionType<ClickupOption>>
        labelList="Tasks"
        value={[]}
        open={!!searchValue && !searchPending}
        anchorRef={inputRef}
        onClose={() => setSearchValue('')}
        options={options.map(option => ({
          value: option,
          label: option.name,
          key: option.key,
        }))}
        fitInAnchor
        labelNoResults="No tasks found"
        labelClear="Cancel"
        type="confirm"
        labelApply="Add task"
        onChange={selectedOptions => {
          const mappedOptions: EpicOption[] = selectedOptions.map(
            option =>
              ({
                key: option.key,
                name: option.name,
                url: option.url,
                owner: { id: values.owner.id, name: values.owner.name },
                review_cycle:
                  values.target_epics?.[0].review_cycle ||
                  values.targets?.[0].review_cycle,
              } as EpicOption),
          )
          if (values.target_epics?.at(0) && values.target_epics[0].epics) {
            values.target_epics[0].epics = uniqBy(
              [...values.target_epics[0].epics, ...mappedOptions],
              'key',
            )
          } else {
            values.target_epics = [
              // @ts-expect-error
              // ignoring errors as interface expects some default values and in form we do not have it at this point
              {
                epics: mappedOptions,
                review_cycle: values.targets?.[0].review_cycle,
                employee_cycle: values.targets?.[0].employee_cycle,
              },
            ]
          }
        }}
      >
        {option => (
          <ClickUpSelectOption
            key={option.key}
            label={option.label}
            taskId={option.value?.key}
            url={option.value?.url}
          />
        )}
      </CheckboxSelect>
      <ClickupSelectedTaskWidget
        tasks={values.target_epics?.at(0)?.epics || []}
        onDelete={(key: string | null) => {
          values.target_epics[0].epics = values.target_epics[0].epics.filter(
            epic => epic.key !== key,
          )
        }}
      />
    </VStack>
  )
}
