import React from 'react'
import { Text } from '@revolut/ui-kit'
import { InterviewFeedbackInterface } from '@src/interfaces/interviewTool'
import { formatTimeAgo } from '@src/utils/format'

type FeedbackInfoProps = {
  feedback: InterviewFeedbackInterface
}

export const FeedbackInfo = ({ feedback }: FeedbackInfoProps) => {
  const { status, updated_date_time } = feedback
  let text = ''
  if (status === 'rejected') {
    text = 'Rejected'
  } else if (status === 'completed' && updated_date_time) {
    text = formatTimeAgo(updated_date_time)
  } else {
    text = 'Awaiting feedback'
  }
  return <Text variant="body2">{text}</Text>
}
