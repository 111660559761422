import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTable } from '@components/Table/hooks'
import { RowInterface } from '@src/interfaces/data'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { canAddRoleKpi, selectUser } from '@src/store/auth/selectors'
import { RoleInterface, SpecialisationInterface } from '@src/interfaces/roles'
import { getKPIFormInitialValues } from '@src/pages/Forms/KpiForm/General'
import { ActionButton, TableWidget } from '@revolut/ui-kit'
import Stat from '@components/Stat/Stat'
import AdjustableTable from '@components/Table/AdjustableTable'
import {
  specialisationGenericNameColumn,
  specialisationHeadcountColumn,
  specialisationManagerColumn,
  specialisationRequisitionsColumn,
  specialisationSkillsColumn,
  specialisationStatusColumn,
} from '@src/constants/columns/specialisation'
import { nipsColumn } from '@src/constants/columns/department'
import { Statuses } from '@src/interfaces'
import { TableNames } from '@src/constants/table'
import { useGetOrganisationSettings } from '@src/api/settings'
import { specialisationsRequests } from '@src/api/specialisations'

interface StructureProps {
  data: RoleInterface
}

const row: RowInterface<SpecialisationInterface> = {
  cells: [
    {
      ...specialisationGenericNameColumn,
      width: 270,
    },
    {
      ...specialisationManagerColumn,
      width: 250,
    },
    {
      ...specialisationHeadcountColumn,
      width: 100,
    },
    {
      ...specialisationRequisitionsColumn,
      width: 110,
    },
    {
      ...nipsColumn,
      width: 100,
    },
    {
      ...specialisationStatusColumn,
      width: 200,
    },
    {
      ...specialisationSkillsColumn,
      width: 600,
    },
  ],
}

const Specialisations = ({ data }: StructureProps) => {
  const { data: orgSettings } = useGetOrganisationSettings()
  const hiddenCells = useMemo(
    () => ({
      [specialisationStatusColumn.idPoint]:
        !orgSettings?.enable_specialisations_approvals,
    }),
    [orgSettings?.enable_specialisations_approvals],
  )

  const initialFilter = [
    {
      filters: [{ name: `${data.id}`, id: data.id }],
      columnName: 'role__id',
      nonResettable: true,
      nonInheritable: true,
    },
    {
      filters: [
        { id: 'pending', name: 'pending' },
        { id: 'approved', name: 'approved' },
        { id: 'draft', name: 'draft' },
      ],
      columnName: 'status',
    },
  ]

  const table = useTable<SpecialisationInterface>(specialisationsRequests, initialFilter)

  const canAdd = useSelector(canAddRoleKpi)
  const user = useSelector(selectUser)

  const handleNewRow = () => {
    navigateTo(pathToUrl(ROUTES.FORMS.SPECIALISATIONS.GENERAL, {}), {
      initialValues: getKPIFormInitialValues(user, {
        role: { name: data.name, id: data.id },
      }),
    })
  }

  const handleRowEdit = ({ id, status }: { id: number; status: Statuses }) => {
    if (status === Statuses.draft) {
      return navigateTo(pathToUrl(ROUTES.FORMS.SPECIALISATIONS.GENERAL, { id }))
    }
    return navigateTo(pathToUrl(ROUTES.FORMS.SPECIALISATIONS.PREVIEW, { id }))
  }

  return (
    <TableWidget>
      <TableWidget.Info>
        <Stat label="Total" val={table?.count} />
      </TableWidget.Info>
      <TableWidget.Actions>
        {canAdd && (
          <ActionButton onClick={handleNewRow} useIcon="Plus">
            Create new specialisation
          </ActionButton>
        )}
      </TableWidget.Actions>
      <TableWidget.Table>
        <AdjustableTable<SpecialisationInterface>
          name={TableNames.RoleSpecialisations}
          useWindowScroll
          dataType="Specialisation"
          row={row}
          hiddenCells={hiddenCells}
          {...table}
          onRowClick={handleRowEdit}
          noDataMessage="Please add Specialisations to see them here."
        />
      </TableWidget.Table>
    </TableWidget>
  )
}

export default Specialisations
