import React, { useEffect, useState } from 'react'
import { RowInterface, SORT_DIRECTION } from '@src/interfaces/data'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { useTable } from '@components/Table/hooks'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import {
  MoreBar,
  Text,
  useStatusPopup,
  StatusPopup,
  Button,
  DataPoint,
  Banner,
  Avatar,
  TextSkeleton,
} from '@revolut/ui-kit'
import { ChevronDown, Plus } from '@revolut/icons'
import { FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import { useSelector } from 'react-redux'
import { selectFeatureFlags, selectPermissions } from '@src/store/auth/selectors'
import {
  reviewCyclesEndDateColumn,
  reviewCyclesNameColumn,
  reviewCyclesNameColumnV2,
  reviewCyclesStartDateColumn,
  reviewCyclesStatusColumn,
  reviewCyclesStatusColumnV2,
  reviewCyclesTimeframeColumn,
} from '@src/constants/columns/reviewCycles'
import {
  changeTestCycleStage,
  getCurrentTestStage,
  reviewCyclesRequests,
} from '@src/api/reviewCycles'
import {
  ReviewCyclesInterface,
  TestReviewCycleStage,
  TestReviewCycleStages,
} from '@src/interfaces/reviewCycles'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { TableNames } from '@src/constants/table'
import { selectorKeys } from '@src/constants/api'
import useFetchOptions from '@components/Inputs/hooks/useFetchOptions'
import { isDev } from '@src/utils'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import { getIconImage } from '@src/pages/Hub/utils'
import { hasTestCycleCookie } from '@src/utils/reviewCycles'
import { useRouteMatch } from 'react-router-dom'
import Table from '@src/components/TableV2/Table'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'
import { useIsNewTable } from '@components/TableV2/hooks'
import { PrimaryAction } from '@src/components/PrimaryAction/PrimaryAction'

interface StatusOption {
  id: TestReviewCycleStages
  name: string
}

const getRow = (
  canEdit: boolean,
  isNewTable: boolean,
): RowInterface<ReviewCyclesInterface> => ({
  linkToForm: ({ id }) =>
    canEdit && navigateTo(pathToUrl(ROUTES.FORMS.REVIEW_CYCLES.GENERAL, { id })),
  cells: isNewTable
    ? [
        {
          ...reviewCyclesNameColumnV2,
          width: 50,
        },
        {
          ...reviewCyclesTimeframeColumn,
          width: 40,
        },
        {
          ...reviewCyclesStatusColumnV2,
          width: 10,
        },
      ]
    : [
        {
          ...reviewCyclesNameColumn,
          width: 230,
        },
        {
          ...reviewCyclesStartDateColumn,
          width: 170,
        },
        {
          ...reviewCyclesEndDateColumn,
          width: 138,
        },
        {
          ...reviewCyclesStatusColumn,
          width: 30,
        },
      ],
})

const popupStagesText: { [stage in TestReviewCycleStages]: string[] } = {
  [TestReviewCycleStages.kpi_setting]: [
    'Set the timeline dates KPI setting to now',
    'Enable users to add KPIs for the test cycle',
    'Trigger notifications to set KPIs',
  ],
  [TestReviewCycleStages.performance_review]: [
    'Set the performance review timeline dates to now',
    'Populate the eligibility table as per the filters set in the test cycle',
    'Trigger the creation of all review scorecards',
    'Create items in the employee performance history',
    'Trigger notifications to submit reviews',
    'Create and update the grade calculations based on the logic set in the test cycle',
  ],
  [TestReviewCycleStages.grade_calibration]: [
    'Set timeline date for calibration to now',
    'Populate all calibration flags',
    'Update calculated grades',
    'Allow calibration of grades from entity talent pages',
    'Trigger notifications to complete calibration',
  ],
  [TestReviewCycleStages.grades_published]: [
    'Set timeline dates for grade publishing to now',
    'Allow managers and reports to view final grades from the employee profile',
    'Update grades in employee performance history and timeline',
  ],
}

const ReviewCyclesTable = () => {
  // TODO: remove when new tables are fully released
  const isNewTable = useIsNewTable()

  const table = useTable<ReviewCyclesInterface>(
    reviewCyclesRequests,
    [],
    isNewTable ? [{ sortBy: 'start_date_time', direction: SORT_DIRECTION.ASC }] : [],
  )
  const permissions = useSelector(selectPermissions)
  const featureFlags = useSelector(selectFeatureFlags)
  const statusPopup = useStatusPopup()

  const canEdit = permissions.includes(PermissionTypes.ChangeReviewCycles)
  const isCommercial = featureFlags?.includes(FeatureFlags.CommercialProduct)
  const canEnableSandbox =
    permissions.includes(PermissionTypes.CanEnableSandboxMode) &&
    featureFlags?.includes(FeatureFlags.TenantSandboxMode)
  const isDevelopment = isDev()
  const testCycleCookie = hasTestCycleCookie()
  // should be available if setting is enabled, i.e. cookie set by BE
  // or for those with permission and feature flag in DEV/for tenants
  const canModifyTestCycleStage =
    testCycleCookie || (canEnableSandbox && (isDevelopment || isCommercial))
  const canAdd = permissions.includes(PermissionTypes.AddReviewCycles) && !testCycleCookie
  const canViewWhitelist = permissions.includes(PermissionTypes.ViewReviewsWhitelist)
  const isPerformanceRoute = useRouteMatch(ROUTES.PERFORMANCE.REVIEWS_CYCLES)
  const canManagePromitions =
    !isPerformanceRoute && permissions.includes(PermissionTypes.ViewPromotionNomination)
  const canNavigateToPerformanceSettings = permissions.includes(
    PermissionTypes.ChangePerformancePreferences,
  )

  const [testCycleValue, setTestCycleValue] = useState<StatusOption | undefined>()

  const { options: stagesOptions, asyncState } = useFetchOptions<{
    id: TestReviewCycleStages
    name: string
  }>(selectorKeys.performance_cycle_stages)
  const onBoardingRequired = !table.loading && table.data.length === 0

  useEffect(() => {
    if (canModifyTestCycleStage) {
      getCurrentTestStage().then(res =>
        setTestCycleValue(res.data.current_stage || undefined),
      )
    }
  }, [canModifyTestCycleStage])

  const showLoading = (option: StatusOption | null) => {
    statusPopup.show(
      <StatusPopup variant="loading">
        <StatusPopup.Title>{`Setting stage to ${option?.name}...`}</StatusPopup.Title>
      </StatusPopup>,
    )
  }
  const showSuccess = (option: StatusOption | null) => {
    statusPopup.show(
      <StatusPopup variant="success">
        <StatusPopup.Title>
          {`Successfully set stage to ${option?.name}`}
        </StatusPopup.Title>
      </StatusPopup>,
    )
  }
  const showError = (option: StatusOption | null) => {
    statusPopup.show(
      <StatusPopup variant="error">
        <StatusPopup.Title>{`Could not set stage to ${option?.name}`}</StatusPopup.Title>
      </StatusPopup>,
    )
  }

  const changeStage = async (option: StatusOption | null) => {
    showLoading(option)
    try {
      if (option) {
        await changeTestCycleStage({
          current_stage: option as TestReviewCycleStage,
        })
        setTestCycleValue(option)
        showSuccess(option)
      }
    } catch {
      showError(option)
    }
  }

  const onSelectStage = (option: StatusOption | null) => {
    statusPopup.show(
      <StatusPopup variant="warning">
        <StatusPopup.Title>
          {`Set review cycle stage as ${option?.name}`}
        </StatusPopup.Title>
        <Text color="grey-tone-50">
          When activating this stage, the following will occur:
          <ul style={{ margin: 0, paddingInlineStart: 20 }}>
            {option?.id &&
              popupStagesText[option.id]?.map((item, ind) => <li key={ind}>{item}</li>)}
          </ul>
          Any actions associated with future stages will be reset.
        </Text>
        <StatusPopup.Actions>
          <Button elevated onClick={() => changeStage(option)}>
            Continue
          </Button>
          <Button variant="secondary" onClick={() => statusPopup.hide()}>
            Cancel
          </Button>
        </StatusPopup.Actions>
      </StatusPopup>,
    )
  }

  return (
    <Table.Widget>
      <Table.Widget.Info>
        {onBoardingRequired ? (
          <Banner>
            <Banner.Avatar>
              <Avatar variant="app" image={getIconImage('performance-reviews', 60)} />
            </Banner.Avatar>
            <Banner.Content>
              <Banner.Title>Performance onboarding is not finished</Banner.Title>
              <Banner.Description>
                Please, use{' '}
                <InternalLink
                  to={pathToUrl(
                    featureFlags.includes(FeatureFlags.OnboardingFlowV2)
                      ? ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.CYCLE
                      : ROUTES.ONBOARDING_CHECKLIST.PERFORMANCE.INTRO,
                  )}
                >
                  performance onboarding flow
                </InternalLink>{' '}
                in order to setup performance settings and create initial review cycles
              </Banner.Description>
            </Banner.Content>
          </Banner>
        ) : (
          <DataPoint>
            <DataPoint.Value>
              {table?.loading ? <TextSkeleton width={50} /> : table?.count}
            </DataPoint.Value>
            <DataPoint.Label>Total</DataPoint.Label>
          </DataPoint>
        )}
      </Table.Widget.Info>
      <Table.Widget.Actions>
        <Table.Widget.MoreBar>
          {canAdd && (
            <PrimaryAction
              disabled={onBoardingRequired}
              use={InternalLink}
              to={pathToUrl(ROUTES.FORMS.REVIEW_CYCLES.GENERAL_INFO)}
              useIcon={Plus}
            >
              Add Review Cycle
            </PrimaryAction>
          )}
          {canNavigateToPerformanceSettings && (
            <MoreBar.Action
              use={InternalLink}
              to={pathToUrl(ROUTES.SETTINGS.PERFORMANCE.REVIEW_CYCLES)}
              useIcon="Gear"
            >
              General cycle settings
            </MoreBar.Action>
          )}
          {canViewWhitelist && (
            <MoreBar.Action
              to={pathToUrl(ROUTES.PERFORMANCE.WHITELIST)}
              use={InternalLink}
              useIcon="Questionnaire"
            >
              Whitelist
            </MoreBar.Action>
          )}
          {canModifyTestCycleStage && (
            <RadioSelectInput
              value={testCycleValue}
              onChange={onSelectStage}
              options={stagesOptions}
              renderInput={(open, setOpen, ref) => (
                <MoreBar.Action
                  disabled={onBoardingRequired}
                  ref={ref}
                  useIcon={ChevronDown}
                  onClick={() => setOpen(!open)}
                  aria-expanded={open}
                >
                  <Text lineHeight="40px">
                    {testCycleValue ? testCycleValue.name : 'Select review cycle stage'}
                  </Text>
                </MoreBar.Action>
              )}
              searchable={false}
              loadingState={asyncState}
              width={290}
            />
          )}
          {canManagePromitions && (
            <MoreBar.Action
              disabled={onBoardingRequired}
              use={InternalLink}
              to={pathToUrl(ROUTES.PERFORMANCE.PROMOTION_NOMINEES)}
              useIcon="ArrowUpgrade"
            >
              Manage Promotions
            </MoreBar.Action>
          )}
        </Table.Widget.MoreBar>
      </Table.Widget.Actions>
      <Table.Widget.Table>
        <AdjustableTable<ReviewCyclesInterface>
          name={TableNames.ReviewCycles}
          hideCount={isNewTable}
          useWindowScroll
          dataType="Review Cycle"
          row={getRow(canEdit, isNewTable)}
          {...table}
          emptyState={<EmptyTableRaw title="Review cycles will appear here." />}
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}

export default ReviewCyclesTable
