import React from 'react'
import {
  Avatar,
  Cell,
  VStack,
  Text,
  Subheader,
  Columns,
  Box,
  Token,
  Spacer,
} from '@revolut/ui-kit'
import { useSelector } from 'react-redux'

import { HUB_INTEGRATIONS_FLAT } from '@src/constants/hub'
import { navigateTo } from '@src/actions/RouterActions'
import { HubIntegrationInterface } from '@src/interfaces/hub'
import { selectFeatureFlags, selectPermissions } from '@src/store/auth/selectors'

interface IntegrationItemProps {
  item: HubIntegrationInterface
}

const IntegrationItem = ({ item }: IntegrationItemProps) => {
  const permissions = useSelector(selectPermissions)

  const enabled = item.canView
    ? item.canView.some(permission => permissions.includes(permission))
    : true

  return (
    <Cell
      onClick={() => navigateTo(item.url)}
      use="button"
      disabled={!enabled}
      height={156}
    >
      <VStack height="100%" style={{ justifyContent: 'flex-start' }}>
        <Avatar variant="brand" image={item.imageV2} bg="transparent" />

        <Spacer />

        <VStack space="s-8">
          <Text variant="primary">{item.title}</Text>
          <Text variant="caption" color={Token.color.greyTone50}>
            {item.description}
          </Text>
        </VStack>
      </VStack>
    </Cell>
  )
}

const IntegrationGrid: React.FC = ({ children }) => {
  return (
    <Columns gap="s-16" defaultCount={3} size={320}>
      {children}
    </Columns>
  )
}

export const Integrations = () => {
  const featureFlags = useSelector(selectFeatureFlags)

  const filterIntegrations = (array: HubIntegrationInterface[]) =>
    array
      .filter(item =>
        item.featureFlags
          ? item.featureFlags.some(flag => featureFlags?.includes(flag))
          : true,
      )
      .filter(Boolean)

  const collaborationGroup = filterIntegrations([
    HUB_INTEGRATIONS_FLAT.google,
    HUB_INTEGRATIONS_FLAT.microsoft,
    HUB_INTEGRATIONS_FLAT.slack,
  ])

  const productivityGroup = filterIntegrations([
    HUB_INTEGRATIONS_FLAT.docusign,
    HUB_INTEGRATIONS_FLAT.jira,
    HUB_INTEGRATIONS_FLAT.looker,
    HUB_INTEGRATIONS_FLAT.clickup,
    HUB_INTEGRATIONS_FLAT.tableau,
  ])

  return (
    <VStack space="s-16">
      {collaborationGroup.length ? (
        <Box>
          <Subheader>
            <Subheader.Title>Collaboration</Subheader.Title>
          </Subheader>
          <IntegrationGrid>
            {collaborationGroup.map(item => (
              <IntegrationItem item={item} key={item.id} />
            ))}
          </IntegrationGrid>
        </Box>
      ) : null}

      {productivityGroup.length ? (
        <Box>
          <Subheader>
            <Subheader.Title>Productivity</Subheader.Title>
          </Subheader>
          <IntegrationGrid>
            {productivityGroup.map(item => (
              <IntegrationItem item={item} key={item.id} />
            ))}
          </IntegrationGrid>
        </Box>
      ) : null}
    </VStack>
  )
}
