import React, { useMemo } from 'react'
import { FilterButton, HStack } from '@revolut/ui-kit'
import { InterviewStageWithoutRoundInterface } from '@src/interfaces/interviewTool'
import FilterButtonCheckboxSelect from '@src/components/FilterButtonCheckboxSelect/FilterButtonCheckboxSelect'

type FilterBarProps = {
  assessmentOnly: boolean
  stages?: InterviewStageWithoutRoundInterface[]
  stagesFilter: number[]
  onAssessmentOnlyChange: () => void
  onStagesFilterChange: (stageIds: number[]) => void
}

export const FilterBar = ({
  assessmentOnly,
  stages,
  stagesFilter,
  onAssessmentOnlyChange,
  onStagesFilterChange,
}: FilterBarProps) => {
  const { options, value } = useMemo(() => {
    const stageOptions = (stages || [])?.map(({ id, title }) => ({
      id,
      name: title,
      disabled: true,
    }))
    const stageValues = stageOptions.filter(({ id }) => stagesFilter.includes(id))
    return {
      options: stageOptions,
      value: stageValues,
    }
  }, [stages, stagesFilter])
  return (
    <HStack gap="s-8">
      <FilterButton
        active={assessmentOnly}
        useIcon={assessmentOnly ? 'SwitchOn' : 'SwitchOff'}
        onClick={onAssessmentOnlyChange}
      >
        Assessments only
      </FilterButton>
      <FilterButtonCheckboxSelect
        label="Filters"
        options={options}
        searchable
        value={value}
        onChange={newStagesFilter => {
          onStagesFilterChange((newStagesFilter || []).map(({ id }) => Number(id)))
        }}
      />
    </HStack>
  )
}
