import React from 'react'
import { ClickUpSelectOption } from './ClickupSelectOption'
import { Token, Widget, Caption, Spacer, VStack } from '@revolut/ui-kit'
import { EpicOption } from '@src/interfaces/kpis'

interface Props {
  tasks: EpicOption[]
  onDelete: (key: string | null) => void
}

export const ClickupSelectedTaskWidget = ({ tasks, onDelete }: Props) => {
  if (!tasks.length) {
    return null
  }
  return (
    <Widget p="s-12" style={{ border: `1px solid ${Token.color.greyTone10}` }}>
      <Caption color={Token.color.greyTone50}>Tasks</Caption>
      <Spacer height="s-12" />
      <VStack space="s-12">
        {tasks.map(task => (
          <ClickUpSelectOption
            key={task.key}
            url={task.url}
            taskId={task.key || undefined}
            onDelete={() => {
              onDelete(task.key)
              //   values.target_epics[0].epics = values.target_epics[0].epics.filter(
              //     epic => epic.key !== task.key,
            }}
            label={task.name}
          />
        ))}
      </VStack>
    </Widget>
  )
}
