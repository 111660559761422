import React from 'react'
import { HStack, Icon, IconName, Text, Token } from '@revolut/ui-kit'
import { InterviewFeedbackInterface } from '@src/interfaces/interviewTool'
import { removeUnderscore } from '@src/utils/string'
import upperFirst from 'lodash/upperFirst'

type FeedbackResultProps = {
  feedback: InterviewFeedbackInterface
}

export const FeedbackResult = ({ feedback }: FeedbackResultProps) => {
  const { result, status } = feedback
  let iconName: undefined | IconName
  let color = Token.color.greyTone50
  let text = 'n/a'
  if (status === 'rejected') {
    color = Token.color.greyTone50
    text = 'n/a'
  } else if (status === 'completed') {
    switch (result) {
      case 'hire':
        iconName = 'SocialLike'
        color = Token.color.success
        break
      case 'strong_hire':
        iconName = 'SocialLike'
        color = Token.color.success
        break
      case 'no_hire':
        iconName = 'SocialDislike'
        color = Token.color.red
        break
      case 'strong_no_hire':
        iconName = 'SocialDislike'
        color = Token.color.red
        break
      default:
        break
    }
    text = result ? removeUnderscore(upperFirst(result)) : ''
  }
  return (
    <HStack align="center" gap="s-4" margin="auto">
      {iconName && <Icon color={color} name={iconName} size={16} />}
      <Text color={color} variant="emphasis2" style={{ whiteSpace: 'nowrap' }}>
        {text}
      </Text>
    </HStack>
  )
}
