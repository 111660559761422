import React from 'react'
import { EntityTypes } from '@src/constants/api'
import {
  analyticsDashboardNameColumn,
  analyticsDashboardRelatedKpisColumn,
  analyticsDashboardOrganisationUnitsColumn,
  analyticsDashboardOwnerColumn,
  analyticsDashboardRatingColumn,
  analyticsDashboardSourceColumn,
  analyticsDashboardRelatedGoalsColumn,
} from '@src/constants/columns/analyticsDashboard'
import { GenericAnalyticsDashboardInterface } from '@src/interfaces/analyticsDashboards'
import { CompanyInterface } from '@src/interfaces/company'
import { RowInterface } from '@src/interfaces/data'
import { AnalyticsDashboardsTab } from '@src/features/AnalyticsDashboardsTab'
import { useIsNewTable } from '@components/TableV2/hooks'

interface Props {
  data: CompanyInterface
}

const initialFilterBy = [
  {
    filters: [{ id: 'true', name: 'true' }],
    columnName: 'company_related',
    nonResettable: true,
  },
]

const row = (isNewTable?: boolean): RowInterface<GenericAnalyticsDashboardInterface> => ({
  cells: [
    {
      ...analyticsDashboardNameColumn,
      width: 200,
    },
    {
      ...analyticsDashboardOrganisationUnitsColumn,
      width: 100,
    },
    {
      ...analyticsDashboardRelatedKpisColumn,
      width: isNewTable ? 100 : 300,
    },
    {
      ...analyticsDashboardRelatedGoalsColumn,
      width: isNewTable ? 100 : 300,
    },
    {
      ...analyticsDashboardRatingColumn,
      width: 40,
    },
    {
      ...analyticsDashboardSourceColumn,
      width: 40,
    },
    {
      ...analyticsDashboardOwnerColumn,
      width: 80,
    },
  ],
})

export const AnalyticsDashboards = ({ data }: Props) => {
  const isNewTable = useIsNewTable()

  return (
    <AnalyticsDashboardsTab
      data={data}
      entity={EntityTypes.company}
      initialFilter={initialFilterBy}
      row={row(isNewTable)}
    />
  )
}
