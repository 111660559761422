import React from 'react'
import { Tag, Text, Token } from '@revolut/ui-kit'
import { InterviewFeedbackInterface } from '@src/interfaces/interviewTool'
import upperFirst from 'lodash/upperFirst'
import { convertOperators } from '@src/pages/Forms/Candidate/Performance/utils'

type FeedbackRatingProps = {
  feedback: InterviewFeedbackInterface
}

export const FeedbackRating = ({ feedback }: FeedbackRatingProps) => {
  const { rating } = feedback
  if (!rating) {
    return (
      <Text variant="emphasis2" color={Token.color.greyTone50}>
        n/a
      </Text>
    )
  }
  return (
    <Tag color={Token.color.greyTone8} style={{ color: Token.color.greyTone50 }}>
      {upperFirst(convertOperators(rating))}
    </Tag>
  )
}
