import { HStack, VStack } from '@revolut/ui-kit'
import { UserAvatar } from '@src/components/UserWithAvatar/UserAvatar'
import { UserLink } from '@src/components/UserWithAvatar/UserLink'
import React from 'react'
import { FeedbackInfo } from '@src/pages/Forms/Candidate/StagesWidget/components/FeedbackInfo'
import { InterviewFeedbackInterface } from '@src/interfaces/interviewTool'

type FeedbackInterviewerProps = {
  feedback: InterviewFeedbackInterface
}

export const FeedbackInterviewer = ({ feedback }: FeedbackInterviewerProps) => {
  const { interviewer = {} } = feedback
  return (
    <HStack gap="s-8">
      <UserAvatar {...interviewer} size={40} />
      <VStack>
        <UserLink
          {...interviewer}
          userNameProps={{
            textProps: { variant: 'emphasis1' },
            ellipsisProps: { maxWidth: '200px' },
          }}
        />
        <FeedbackInfo feedback={feedback} />
      </VStack>
    </HStack>
  )
}
