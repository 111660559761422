import React from 'react'
import {
  Token,
  VStack,
  Widget,
  Text,
  Icon,
  Subheader,
  DetailsCell,
  Copyable,
  copyToClipboard,
  TravelWidget,
  HStack,
  Spacer,
} from '@revolut/ui-kit'
import OpenInNewTab from '@src/components/OpenInNewTab/OpenInNewTab'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle, {
  NewStepperTitleType,
} from '@src/components/Stepper/NewStepperTitle'
import { pushNotification } from '@src/store/notifications/actions'
import { NotificationTypes } from '@src/store/notifications/types'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { IntegrationForm } from '../../../../Forms/GoalForm/Form/GoalMetricForm/constants'
import NewResponsiveCodeEditor from '@src/components/Inputs/NewCodeEditor/NewResponsiveCodeEditor'

const getBodyContent = (redirect_uri?: string) => {
  return `{\n"redirect_uri": "${redirect_uri}",\n"display_name": "Revolut People",\n"description":"Used to integrate Looker with Revolut People",\n"enabled": true,\n"group_id": ""\n}`
}

interface Props {
  setLightBoxSrc: (src: { video: string; poster: string } | undefined) => void
}

export const LookerHelperContent = ({ setLightBoxSrc }: Props) => {
  const { values } = useLapeContext<IntegrationForm>()

  return (
    <VStack space="s-16">
      <Widget>
        <AutoStepper>
          <NewStepperTitle
            type={NewStepperTitleType.coreUI}
            title="Login to your Looker account"
            bulletVariant="filled"
            subtitle={
              <VStack pt="s-12" space="s-12">
                <VStack>
                  <Text variant="emphasis1" color={Token.color.foreground}>
                    Navigate to Applications and select the API Explorer
                  </Text>
                  <Text variant="body2" color={Token.color.greyTone50}>
                    You need admin rights to do this
                  </Text>
                </VStack>
                <VStack space="s-8">
                  <Text variant="emphasis1" color={Token.color.foreground}>
                    Search for <Text fontWeight={700}>“Register OAuth app”</Text> and
                    click <Text fontWeight={700}>“Run It”</Text>
                  </Text>
                  <OpenInNewTab
                    link={`${values.server}/extensions/marketplace_extension_api_explorer::api-explorer/4.0/methods/Auth/register_oauth_client_app`}
                    label="Take me there"
                  />
                </VStack>
              </VStack>
            }
          />
          <NewStepperTitle
            type={NewStepperTitleType.coreUI}
            title={
              <Text>
                Fill in the <Text fontWeight={700}>client_guid</Text> and{' '}
                <Text fontWeight={700}>body</Text>, and hit{' '}
                <Text fontWeight={700}>“Run”</Text>
              </Text>
            }
            subtitle="Check the screenshot below to locate the fields that need to be filled"
            bulletVariant="filled"
          />
        </AutoStepper>
      </Widget>

      <Subheader style={{ paddingTop: 0, paddingBottom: 0 }}>
        Details to enter <Icon name="InfoOutline" color={Token.color.greyTone50} />
      </Subheader>
      <Widget>
        <DetailsCell>
          <DetailsCell.Title>client_guid</DetailsCell.Title>

          <DetailsCell.Content>
            <Copyable
              value={values.client_guid}
              style={{ flexDirection: 'row-reverse', gap: Token.space.s8 }}
              onCopy={value => {
                copyToClipboard(value).then(() => {
                  pushNotification({
                    value: 'client_guid copied to clipboard',
                    duration: SUCCESS_DEFAULT_DURATION,
                    type: NotificationTypes.success,
                  })
                })
              }}
            >
              Copy ID
            </Copyable>
          </DetailsCell.Content>
          <DetailsCell.Note>{values.client_guid || 'unknown'}</DetailsCell.Note>
        </DetailsCell>
      </Widget>
      <Widget>
        <DetailsCell>
          <DetailsCell.Title>Body</DetailsCell.Title>

          <DetailsCell.Content>
            <Copyable
              value={getBodyContent(values.redirect_uri)}
              style={{ flexDirection: 'row-reverse', gap: Token.space.s8 }}
              onCopy={value => {
                copyToClipboard(value).then(() => {
                  pushNotification({
                    value: 'body copied to clipboard',
                    duration: SUCCESS_DEFAULT_DURATION,
                    type: NotificationTypes.success,
                  })
                })
              }}
            >
              Copy code
            </Copyable>
          </DetailsCell.Content>
          <DetailsCell.Note>
            <NewResponsiveCodeEditor
              height={140}
              readonly
              language="json"
              value={getBodyContent(values.redirect_uri)}
            />
          </DetailsCell.Note>
        </DetailsCell>
      </Widget>
      {/* @ts-expect-error actually can use py here */}
      <Subheader py={0}>
        Where to enter? <Icon name="InfoOutline" color={Token.color.greyTone50} />
      </Subheader>
      <TravelWidget
        use="div"
        variant="large"
        image={[
          'https://assets.revolut.com/revoluters/assets/integrations/looker_integration_help_preview.png',
        ]}
      >
        <TravelWidget.Details
          use="button"
          onClick={() =>
            setLightBoxSrc({
              video:
                'https://assets.revolut.com/revoluters/assets/integrations/looker_integration_help.mp4',
              poster:
                'https://assets.revolut.com/revoluters/assets/integrations/looker_integration_help_preview.png',
            })
          }
        >
          <HStack align="center">
            <Text>Enlarge video</Text>
            <Spacer />
            <Icon name="ChevronRight" />
          </HStack>
        </TravelWidget.Details>
      </TravelWidget>
    </VStack>
  )
}
