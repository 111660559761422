import { HStack, Icon, Text, Token } from '@revolut/ui-kit'
import {
  InterviewRoundInterface,
  InterviewStageWithoutRoundInterface,
} from '@src/interfaces/interviewTool'
import React from 'react'

type StageStatusProps = {
  round: InterviewRoundInterface
  stage: InterviewStageWithoutRoundInterface
}

export const StageStatus = ({ round, stage }: StageStatusProps) => {
  if (round?.latest_interview_stage?.id !== stage.id) {
    return null
  }
  return (
    <HStack align="center" color={Token.color.accent} gap="s-8">
      <Icon name="Time" size={12} />
      <Text variant="emphasis4">Current stage</Text>
    </HStack>
  )
}
