import React, { useState } from 'react'
import {
  VStack,
  DataPoint,
  Flex,
  Cell,
  Box,
  Tag,
  useTooltip,
  Tooltip,
  Token,
} from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { GoalPreviewInterface } from '@src/interfaces/goals'
import { fetchGoalGraph, getGoalCommentsAPI, useGetGoal } from '@src/api/goals'
import { FilterByInterface } from '@src/interfaces/data'
import { OptionInterface } from '@src/interfaces/selectors'
import { RoadmapsWidget } from '../Widgets/Roadmaps/RoadmapsWidget'
import CommentsSection from '@src/features/Comments/CommentsSection'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { OverallProgress } from '@src/features/Goals/components/OverallProgress'
import { useGoalRoadmapsTable } from '@src/features/Goals/common/useGoalRoadmapsTable'
import { GoalStatusDropdown } from '@src/features/Goals/components/GoalStatusDropdown'
import { TargetsPreviewWidget } from './TargetsPreviewWidget'
import { AnalyticsPreviewWidget } from './AnalyticsPreviewWidget'
import { FormPreview } from '@src/components/FormPreview/FormPreview'
import { GoalCycleFilter } from '@src/features/Goals/components/GoalCycleFilter'
import { GoalCycleSelectType } from './GoalPreviewPage'
import { isOnboardingPath } from '@src/pages/OnboardingChecklistV2/common/helpers'
import { RoadmapSidebarForm } from '../Widgets/Roadmaps/RoadmapSidebarForm'
import { useGetRoadmapSettings } from '@src/api/settings'
import { ChildGoalsPreviewFormWidget } from './ChildGoalsPreviewWidget'
import { isCascadedGoal } from '@src/pages/Forms/GoalForm/common/utils'
import { IntegrationDataPreviewWidget } from './IntergrationDataPreviewWidget'

export const GoalsPreviewLeftSide = ({
  cycle,
  availableCycles,
  onCycleChanged,
}: {
  cycle: GoalCycleSelectType
  availableCycles: GoalCycleSelectType[]
  onCycleChanged: (cycleId: number | string) => void
}) => {
  const { values } = useLapeContext<GoalPreviewInterface>()
  const { data: parent } = useGetGoal(values.parent?.id)
  const roadmapsTable = useGoalRoadmapsTable(cycle)
  const { data: roadmapsSettings } = useGetRoadmapSettings()
  const [filteredAvailableCycles, setFilteredAvailableCycles] = useState(availableCycles)
  const [selectedRoadmap, setSelectedRoadmap] = useState<number>()
  const tooltip = useTooltip()

  const isManualRoadmaps = !roadmapsSettings?.jira_epics_enabled
  const isCascaded = isCascadedGoal(values.update_type)

  const onCycleChange = (filter: FilterByInterface) => {
    const cycleId = filter.filters[0].id

    onCycleChanged(cycleId)
    if (
      filter.filters[0].category &&
      !['probation', 'PIP'].includes(filter.filters[0].category)
    ) {
      roadmapsTable.onFilterChange({ ...filter, columnName: 'review_cycle__id' })
    }
  }

  const handleTableSelected = (tab: 'chart' | 'table') => {
    if (tab === 'table') {
      onCycleChange({
        filters: [availableCycles[0] as OptionInterface],
        columnName: 'cycle__id',
      })
      setFilteredAvailableCycles([availableCycles[0]])
    } else {
      setFilteredAvailableCycles(availableCycles)
    }
  }
  const cycleFilter = [
    {
      columnName: 'cycle__id',
      filters: [{ id: cycle?.id, name: cycle?.name }],
    },
  ] as FilterByInterface[]

  const goalCycles =
    (values.update_type?.id === 'cascaded' ? parent?.goal_cycles : values.goal_cycles) ||
    []

  const goalProgressDetails = goalCycles.find(cycleDetails => {
    if (cycleDetails.review_cycle) {
      return cycleDetails.review_cycle.id === cycle.id
    }
    if (cycleDetails.employee_cycle) {
      return cycleDetails.employee_cycle.id === cycle.id
    }
    return false
  })

  const progress =
    goalProgressDetails?.calibrated_progress ??
    goalProgressDetails?.progress ??
    values.calibrated_progress ??
    values.progress

  const showTargets =
    values.update_type?.id === 'target_based' || values.update_type?.id === 'cascaded'

  return (
    <VStack space="s-24">
      <Cell width="100%">
        <VStack width="100%">
          <Flex justifyContent="space-between">
            <Flex width="100%" gap="s-24">
              <GoalCycleFilter
                isFilterButtonUI={false}
                allowEmployeeCycles={false}
                onFilterChange={onCycleChange}
                columnName="cycle__id"
                filter={cycleFilter}
                selector={() =>
                  Promise.resolve({
                    options: filteredAvailableCycles as unknown as OptionInterface[],
                  })
                }
              />
              <OverallProgress
                value={progress}
                id={values.id}
                fetchData={fetchGoalGraph}
              />
              <DataPoint>
                <DataPoint.Value aria-labelledby="goalStatus">
                  <GoalStatusDropdown
                    labelVariant="h5"
                    goal={values}
                    onStatusChanged={status => {
                      values.status = status
                    }}
                  />
                </DataPoint.Value>
                <DataPoint.Label id="goalStatus">Status</DataPoint.Label>
              </DataPoint>
            </Flex>
            {isCascaded && (
              <Tag
                variant="outlined"
                color={Token.color.deepGrey}
                style={{ cursor: 'pointer' }}
                {...tooltip.getAnchorProps()}
              >
                Cascaded
                <Tooltip {...tooltip.getTargetProps()} placement="top" maxWidth={280}>
                  This is a cascaded goal. To refresh the progress, please access the
                  parent goal
                </Tooltip>
              </Tag>
            )}
          </Flex>
          <Box mx="-s-16">
            <FormPreview data={values}>
              {!!values.parent && (
                <FormPreview.Item
                  title="Parent goal"
                  field="parent.name"
                  href={() =>
                    pathToUrl(
                      isOnboardingPath()
                        ? ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.GOAL.PREVIEW
                        : ROUTES.FORMS.GOAL.PREVIEW,
                      {
                        id: String(values.parent?.id),
                      },
                    )
                  }
                />
              )}
              {!!values.description && (
                <FormPreview.Details
                  title="Description"
                  field="description"
                  type="showMore"
                />
              )}
            </FormPreview>
          </Box>
        </VStack>
      </Cell>
      {values.update_type?.id === 'aggregated' && values.is_top_level && (
        <ChildGoalsPreviewFormWidget parentId={values.id} cycle={cycle} />
      )}
      {showTargets && (
        <TargetsPreviewWidget
          onChange={handleTableSelected}
          cycle={cycle}
          updateType={values.update_type}
          availableCycles={availableCycles}
        />
      )}
      <IntegrationDataPreviewWidget />
      {!!roadmapsTable.data?.length && (
        <RoadmapsWidget
          viewMode
          table={roadmapsTable}
          variant="preview"
          onSelected={isManualRoadmaps ? r => setSelectedRoadmap(r.id) : undefined}
          updateType={values.update_type}
        />
      )}
      {values.dashboards?.length ? <AnalyticsPreviewWidget /> : null}
      <GoalsCommentsSection goalId={values.id} />

      {isManualRoadmaps ? (
        <RoadmapSidebarForm
          roadmapId={selectedRoadmap}
          onClose={() => setSelectedRoadmap(undefined)}
          onSuccess={() => {
            roadmapsTable.refresh()
            setSelectedRoadmap(undefined)
          }}
        />
      ) : null}
    </VStack>
  )
}

function GoalsCommentsSection({ goalId }: { goalId: number }) {
  const api = getGoalCommentsAPI(goalId)

  return <CommentsSection api={api} />
}
