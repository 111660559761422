import React, { useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { upperFirst } from 'lodash'

import IconButton from '../ButtonIcon/IconButton'
import Icon from '../Icon/Icon'
import {
  ColumnCellInterface,
  DirectionType,
  FilterByInterface,
  FilterOption,
  FilterType,
  SORT_DIRECTION,
  SortByInterface,
} from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import { OptionInterface } from '@src/interfaces/selectors'
import Tooltip from '../Tooltip/Tooltip'
import { isTouchDevice } from '@src/utils/mobile'
import { chain, Ellipsis, HStack, Token } from '@revolut/ui-kit'
import { RowHeight, TableTypes } from '@src/interfaces/table'
import { HeaderCellFilter } from '@components/TableV2/HeaderCellFilter'

const DraggableMatrixCss = css`
  right: -3.5px;
`

const Draggable = styled.div<{ type: TableTypes }>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0;
  width: 7px;
  cursor: col-resize;
  ${({ type }) => type === TableTypes.Matrix && DraggableMatrixCss};

  &::after {
    content: '';
    width: 1px;
    transition: background-color 200ms ease-in-out;
    height: 16px;
    background-color: ${Token.color.greyTone50};
  }
`

const TableCellShow = styled.div<{ headerAlign?: 'left' | 'center' | 'right' }>`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  padding-right: ${props => (props.headerAlign === 'center' ? 0 : 12)}px;

  &:hover > ${Draggable}::after {
    opacity: 1;
  }
`

const FilterButton = styled(IconButton)<{ active?: boolean }>`
  padding: 4px;
  border-radius: 50%;
  flex-shrink: 0;
  color: ${props => (props.active ? Token.color.blue : Token.color.foreground)};
  background-color: ${props =>
    props.active ? 'rgba(0, 117, 235, 0.06)' : 'transparent'};
`

const MaskButton = styled(IconButton)`
  color: ${Token.color.greyTone50};
`

const MaskButtonTooltip = styled(Tooltip)`
  display: inline-flex;
  padding: 4px;
  vertical-align: middle;
  margin-right: -4px;
`

const Title = styled.div<{
  active: boolean
  headerAlign?: 'left' | 'center' | 'right'
  type: TableTypes
}>`
  padding: ${props =>
    props.type === TableTypes.Contained
      ? props.headerAlign === 'center'
        ? '12px 0'
        : '12px 0 12px 12px'
      : props.headerAlign === 'center'
      ? '10px 0'
      : '10px 0 10px 12px'};
  flex: 1;
  text-align: ${props => props.headerAlign || 'inherit'};
  text-transform: inherit;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  color: ${props => (props.active ? Token.color.blue_60 : Token.color.greyTone50)};
`

const CancelButton = styled(IconButton)`
  flex-shrink: 0;
  &:hover {
    opacity: 1;
  }
`

const CustomIcon = styled(Icon)`
  margin-left: 4px;
  margin-bottom: 1px;
`

const ArrowIcon = styled(Icon)<{
  active: boolean
  desc: boolean
}>`
  margin-left: 5px;
  transition: all 150ms ease-in;
  color: ${props => (props.active ? Token.color.blue_60 : Token.color.foreground)};
  opacity: ${props => (props.active ? 1 : 0.15)};
  transform: ${props => (props.desc ? 'rotate(0deg)' : 'rotate(180deg)')};
`

const AdjustableCellWrapper = css`
  border-bottom: none;
  //background-color: ${Token.color.widgetBackground};
`

export const HEADER_CELL_WRAPPER = 'header-cell-wrapper'

const headerCellWrapperFontSize = {
  small: 12,
  medium: 13,
  large: 14,
}

const headerCellWrapperLineHeight = {
  small: 14,
  medium: 18,
  large: 22,
}

export const HeaderCellWrapper = styled.div.attrs({ className: HEADER_CELL_WRAPPER })<{
  type: TableTypes
  width?: number
  rowHeight: RowHeight
}>`
  position: relative;
  display: flex;
  text-align: left;
  background: inherit;
  font-size: ${({ rowHeight }) => `${headerCellWrapperFontSize[rowHeight]}px`};
  line-height: ${({ rowHeight }) => `${headerCellWrapperLineHeight[rowHeight]}px`};
  white-space: nowrap;
  font-weight: 500;
  ${props =>
    props.width
      ? `
  max-width: ${props.width}px;
  min-width: ${props.width}px;
  width: ${props.width}px;`
      : 'flex: 1'};
  color: ${Token.color.greyTone50};
  border-bottom: 1px solid ${Token.color.greyTone8}
    ${({ type }) =>
      (type === TableTypes.Adjustable || type === TableTypes.Contained) &&
      AdjustableCellWrapper};
`

const HeaderTooltip = styled(Tooltip)`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  vertical-align: middle;
`

const HeaderTooltipTitle = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

export interface HeaderRowInterface {
  cell: ColumnCellInterface<any>
  noResize?: boolean
  onFilterChange?: (filters: FilterByInterface, reset?: boolean) => void
  onSortChange?: (sortBy: SortByInterface) => void
  fetchSelectors?: (key: selectorKeys) => () => Promise<{ options: OptionInterface[] }>
  sortBy?: DirectionType
  filterBy?: FilterOption[]
  changeColumn: (idPoint: string, name: string, value: any) => void
  type: TableTypes
  headerAlign?: 'left' | 'center' | 'right'
  disabled?: boolean
  rowHeight: RowHeight
  disabledSorting?: boolean
  count?: number
}

const HeaderCell = ({
  cell,
  sortBy,
  filterBy,
  onSortChange,
  fetchSelectors,
  noResize,
  onFilterChange,
  changeColumn,
  type,
  headerAlign,
  disabled,
  rowHeight,
  disabledSorting,
  count,
}: HeaderRowInterface) => {
  const popoverAnchor = useRef<HTMLDivElement>(null)
  const [filterPopoverOpen, setFilterPopoverOpen] = useState(false)
  const [hovered, setHovered] = useState(isTouchDevice())
  const [arrowHovered, setArrowHovered] = useState(false)
  const wrapper = useRef<HTMLDivElement>(null)

  const handleOpenFilter = () => {
    setFilterPopoverOpen(true)
  }

  const handleCloseFilter = () => {
    setFilterPopoverOpen(false)
  }

  const handleFilterChange = (options: FilterOption[]) => {
    setFilterPopoverOpen(false)
    if (!isTouchDevice()) {
      setHovered(false)
    }
    onFilterChange?.({ columnName: cell.filterKey!, filters: options })
  }

  const handleMaskButton = (e: React.MouseEvent) => {
    e.stopPropagation()
    changeColumn(cell.idPoint, 'masked', !cell.masked)
  }

  const handleMouseDown = (e: React.MouseEvent) => {
    e.preventDefault()

    const initialWidth = cell.width!
    const initialX = e.pageX
    const handleMouseMove = (event: MouseEvent) => {
      const diff = event.pageX - initialX
      const newWidth = initialWidth + diff
      if (newWidth > 50) {
        changeColumn(cell.idPoint, 'resizeWidth', diff)
      }
    }
    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove)
      document.removeEventListener('mouseup', handleMouseUp)
    }
    document.addEventListener('mousemove', handleMouseMove)
    document.addEventListener('mouseup', handleMouseUp)
  }

  const handleClick = () => {
    if (!cell.sortKey || disabled || disabledSorting) {
      return undefined
    }
    const firstSortDir =
      cell.filterType === FilterType.range || cell.filterType === FilterType.percentRange
        ? SORT_DIRECTION.ASC
        : SORT_DIRECTION.DESC
    const secondSortDir =
      firstSortDir === SORT_DIRECTION.ASC ? SORT_DIRECTION.DESC : SORT_DIRECTION.ASC
    if (!sortBy) {
      return onSortChange?.({ sortBy: cell.sortKey, direction: firstSortDir })
    }
    if (sortBy === firstSortDir) {
      return onSortChange?.({ sortBy: cell.sortKey, direction: secondSortDir })
    }
    return onSortChange?.({ sortBy: cell.sortKey, direction: undefined })
  }
  const handleClearSort = () => {
    if (sortBy) {
      return onSortChange?.({ sortBy: cell.sortKey!, direction: undefined })
    }
    return handleClick()
  }

  const renderHeaderTooltip = () => {
    const title = (
      <HStack>
        {chain(<Ellipsis>{upperFirst(cell.title)}</Ellipsis>, cell.count || count)}
      </HStack>
    )

    if (!cell.headerTooltip) {
      return title
    }

    return (
      <HeaderTooltip placement="top" body={cell.headerTooltip}>
        <HeaderTooltipTitle>{title}</HeaderTooltipTitle>
        <CustomIcon type="Info" size="tiny" />
      </HeaderTooltip>
    )
  }

  return (
    <HeaderCellWrapper
      ref={wrapper}
      type={type}
      width={cell.width + (cell.resizeWidth || 0)}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      rowHeight={rowHeight}
      data-testid={`HeaderCell-${cell.title}`}
    >
      <TableCellShow headerAlign={headerAlign}>
        <Title
          ref={popoverAnchor}
          onClick={handleClick}
          active={!!filterBy?.length || !!sortBy}
          headerAlign={headerAlign}
          type={type}
        >
          {renderHeaderTooltip()}
        </Title>
        {!disabled && !disabledSorting && cell.sortKey && (hovered || sortBy) && (
          <CancelButton
            onMouseEnter={() => setArrowHovered(true)}
            onMouseLeave={() => setArrowHovered(false)}
            onClick={handleClearSort}
          >
            <ArrowIcon
              type={arrowHovered && sortBy ? 'Close' : 'ArrowUpLong'}
              active={!!sortBy}
              desc={sortBy === SORT_DIRECTION.DESC}
              size="tiny"
            />
          </CancelButton>
        )}
        {!disabled && cell.filterKey && (hovered || !!filterBy?.length) && (
          <FilterButton active={!!filterBy?.length} onClick={handleOpenFilter}>
            <Icon size="tiny" type="FilterList" />
          </FilterButton>
        )}
        {cell.masked !== undefined && (
          <MaskButtonTooltip
            placement="top"
            body={cell.masked ? cell.maskTooltip?.isHidden : cell.maskTooltip?.isShown}
          >
            <MaskButton onClick={handleMaskButton}>
              <Icon size="small" type={cell.masked ? 'Eye' : 'EyeCrossed'} />
            </MaskButton>
          </MaskButtonTooltip>
        )}
        {popoverAnchor.current && filterPopoverOpen && (
          <HeaderCellFilter
            filterType={cell.filterType}
            cellType={cell.type}
            open={filterPopoverOpen}
            onClose={handleCloseFilter}
            onChange={handleFilterChange}
            value={filterBy}
            anchorRef={popoverAnchor}
            dataPoint={cell.dataPoint}
            fetchSelectors={fetchSelectors}
            selectorsKey={cell.selectorsKey}
          />
        )}
        {hovered && cell.width && !noResize && (
          <Draggable onMouseDown={handleMouseDown} type={type} />
        )}
      </TableCellShow>
    </HeaderCellWrapper>
  )
}

export default HeaderCell
