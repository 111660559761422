import React from 'react'
import { Color, Contact, IconName, Token, abbreviate } from '@revolut/ui-kit'

import { IdAndName } from '@src/interfaces'
import { EmployeeEventType } from '@src/interfaces/employeeEvents'

export const employeeEventToColor: Record<EmployeeEventType, Color> = {
  birthday: Token.color.purple,
  anniversary: Token.color.pink,
  time_off: Token.color.orange,
}

export const employeeEventToIcon: Record<EmployeeEventType, IconName> = {
  birthday: 'Cake' as const,
  anniversary: 'Trophy' as const,
  time_off: 'Resort' as const,
}

interface EmployeeEventAvatarProps {
  eventType: IdAndName<EmployeeEventType>
  avatar: string | null
  fullName: string
  showTitle: boolean
}

export const EmployeeEventAvatar = ({
  eventType,
  avatar,
  fullName,
  showTitle,
}: EmployeeEventAvatarProps) => {
  return (
    <Contact
      image={avatar}
      label={abbreviate(fullName)}
      progress={1}
      progressColor={employeeEventToColor[eventType.id]}
      style={{ pointerEvents: 'none' }}
    >
      <Contact.Badge
        useIcon={employeeEventToIcon[eventType.id]}
        bg={employeeEventToColor[eventType.id]}
      />
      {showTitle ? <Contact.Title>{fullName}</Contact.Title> : null}
    </Contact>
  )
}
