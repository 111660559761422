import { SelectOptionItemType } from '@revolut/ui-kit'
import { Id } from '@src/interfaces'
import {
  CandidateHiringStageRow,
  InterviewFeedbackInterface,
  InterviewRoundInterface,
} from '@src/interfaces/interviewTool'

export const isFeedback = (
  data: CandidateHiringStageRow | InterviewFeedbackInterface,
): data is InterviewFeedbackInterface => !('children' in data)

export type NewOption = Id<'new'>
export type OptionType = InterviewRoundInterface | NewOption
export type SelectOptionType = SelectOptionItemType<OptionType>

export const isNewOption = (
  option: SelectOptionType,
): option is SelectOptionItemType<NewOption> => String(option.key) === 'new'

export const isInterviewRound = (
  option: SelectOptionType,
): option is SelectOptionItemType<InterviewRoundInterface> => String(option.key) !== 'new'
