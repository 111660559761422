import React, { useEffect, useRef, useState } from 'react'
import {
  VStack,
  Box,
  InputGroup,
  Token,
  Footnote,
  Flex,
  Icon,
  Link,
  Image,
} from '@revolut/ui-kit'
import styled from 'styled-components'
import { connect } from 'lape'

import { HeroBlockContentBase, HeroBlockContentBaseLayout } from './HeroBlockContent'
import { Button } from './Button'
import { salesContactRequests } from '../api'
import { contactUsBackgroundColor } from '../styles'
import { Text } from '../components/Text'
import Form from '@src/features/Form/Form'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import { LapePhoneInput } from '@src/components/Inputs/LapeFields/LapePhoneInput'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ReCaptcha } from '@src/components/ReCaptcha/ReCaptcha'
import { ReCaptchaHandle } from '@src/interfaces/reCaptcha'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { PUBLIC_ROUTES, ROUTES } from '@src/constants/routes'
import { useSafeFormValidator } from '@src/features/Form/FormValidator'
import { pathToUrl } from '@src/utils/router'
import {
  // CUSTOMER_1,
  CUSTOMER_2,
  CUSTOMER_3,
  CUSTOMER_4,
  CUSTOMER_5,
  CUSTOMER_6,
} from '@src/pages/Landing/constants'
import { useLocation } from 'react-router-dom'
import { useAnalytics, AnalyticsEvents } from '@src/utils/analytics'

const ContactUsFormContents = () => {
  const { sendAnalyticsEvent } = useAnalytics()
  const location = useLocation<{ email?: string }>()
  const [hasSubmitted, setHasSubmitted] = useState(false)

  const captchaRef = useRef<ReCaptchaHandle>(null)
  const successMessageRef = useRef<HTMLDivElement>(null)
  const { values, errors, isSubmitting, submit } =
    useLapeContext<{ captcha: string; email: string }>()
  const { validate } = useSafeFormValidator()

  useEffect(() => {
    values.email = location.state?.email || ''
  }, [])

  useEffect(() => {
    if (hasSubmitted) {
      successMessageRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [hasSubmitted])

  const onSubmit = async () => {
    sendAnalyticsEvent(AnalyticsEvents.send_contact_request)
    const token = await captchaRef.current?.verify()

    if (token) {
      values.captcha = token
    }

    return submit()
      .then(() => {
        setHasSubmitted(true)
        sendAnalyticsEvent(AnalyticsEvents.contact_request_success)
      })
      .catch(() => {
        sendAnalyticsEvent(AnalyticsEvents.contact_request_failed)
      })
      .finally(() => {
        captchaRef.current?.reset()
      })
  }

  if (hasSubmitted) {
    return (
      <VStack
        space="s-8"
        style={{ textAlign: 'center' }}
        align="center"
        ref={successMessageRef}
      >
        <Icon name="CheckSuccess" color={Token.color.blue} />
        <Text variant="h6">Thanks!</Text>
        <Text variant="secondary">
          We'll get back to you within 24 hours. In the meantime, check out our{' '}
          <Link use={InternalLink} to={ROUTES.MAIN}>
            website
          </Link>
        </Text>
      </VStack>
    )
  }

  return (
    <InputGroup data-form="contact-us">
      <InputGroup variant="horizontal">
        <LapeNewInput name="first_name" label="First name" required />
        <LapeNewInput name="last_name" label="Last name" required />
      </InputGroup>
      <LapeNewInput name="email" label="Email" required />
      <LapeNewInput name="company_name" label="Company name" required />
      <LapePhoneInput
        prefixName="phone_country_code"
        phoneName="phone_number_short"
        required
      />
      <LapeRadioSelectInput
        name="country"
        label="Country"
        selector={selectorKeys.countries}
        required
      />
      <LapeRadioSelectInput
        name="company_size"
        label="Company size"
        selector={selectorKeys.company_sizes}
        required
      />
      <LapeNewInput name="comment" label="Add comment" required />
      <ReCaptcha
        onVerify={value => {
          values.captcha = value
        }}
        name="captcha"
        error={errors?.captcha}
        ref={captchaRef}
      />

      <Footnote style={{ marginTop: Token.space.s8 }}>
        By proceeding you agree to the{' '}
        <Link href={pathToUrl(PUBLIC_ROUTES.TERMS_AND_CONDITIONS)} target="_blank">
          Revolut People Terms of Service
        </Link>{' '}
        and our{' '}
        <Link href={pathToUrl(PUBLIC_ROUTES.PRIVACY_NOTICE)} target="_blank">
          Privacy Policy
        </Link>
      </Footnote>
      <Button
        variant="primary"
        onClick={validate(onSubmit)}
        pending={isSubmitting}
        width="100%"
        maxWidth={{ sm: '720px' }}
      >
        Book a demo
      </Button>
    </InputGroup>
  )
}

const ContactUsForm = connect(() => {
  return (
    <Box
      style={{ textAlign: 'start' }}
      maxWidth={{ all: 560, lg: 375 }}
      minWidth={{ lg: 375 }}
      backgroundColor={contactUsBackgroundColor}
      p={{ all: 0, md: 's-16' }}
      radius={Token.radius.widget}
    >
      <Form api={salesContactRequests} disableLocalStorageCaching>
        <ContactUsFormContents />
      </Form>
    </Box>
  )
})

const TextContainer = styled(VStack)`
  text-align: center;

  @media ${Token.media.md} {
    text-align: center;
  }
  @media ${Token.media.lg} {
    text-align: start;
  }
`

export const ContactUsHeroBlockContent = () => {
  return (
    <HeroBlockContentBaseLayout>
      <HeroBlockContentBase align="center">
        <Flex
          gap="s-32"
          flexDirection={{ all: 'column', lg: 'row' }}
          pt={{ all: '0', lg: '125px' }}
        >
          <TextContainer
            align={{ all: 'center', lg: 'start' }}
            space={{ all: 's-8', lg: 's-24' }}
            pt={{ all: 's-72' }}
            width="100%"
          >
            <Text use="h2" variant="h2" whiteSpace="pre-wrap" width="100%">
              See Revolut People in action
            </Text>
            <Text
              use="p"
              whiteSpace="pre-wrap"
              // @ts-expect-error Custom theme for this LandingPage is used. TODO: REVPI-28 Migrate LandingPage to standard UI Kit
              variant="subtitle1"
            >
              Fill in your details and one of our product experts will reach out to
              schedule a demo
            </Text>
            <Box pl="s-16" display={{ all: 'inline-block', md: 'block' }}>
              <Text
                // @ts-expect-error Custom theme for this LandingPage is used. TODO: REVPI-28 Migrate LandingPage to standard UI Kit
                variant="subtitle1"
              >
                <ul style={{ textAlign: 'left' }}>
                  <li>Raise the bar with trusted reviews 🎯</li>
                  <li>Hire with precision at any scale 👤</li>
                  <li>Engage a global workforce with core HR 🌎</li>
                </ul>
              </Text>
            </Box>

            <VStack space="s-24" display={{ all: 'none', lg: 'flex' }} pt="s-72">
              <Text use="h4" variant="h4" color={Token.color.greyTone50}>
                POWERING EUROPE’S GROWING TECH COMPANIES
              </Text>

              <Flex flexWrap="wrap">
                {/* <Image alt="" src={CUSTOMER_1} height={144} width={168} /> */}
                <Image alt="" src={CUSTOMER_2} height={144} width={168} />
                <Image alt="" src={CUSTOMER_3} height={144} width={168} />
                <Image alt="" src={CUSTOMER_4} height={144} width={168} />
                <Image alt="" src={CUSTOMER_5} height={144} width={168} />
                <Image alt="" src={CUSTOMER_6} height={144} width={168} />
              </Flex>
            </VStack>
          </TextContainer>

          <Box margin="0 auto">
            <ContactUsForm />
          </Box>
        </Flex>
      </HeroBlockContentBase>
    </HeroBlockContentBaseLayout>
  )
}
