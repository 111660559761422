import React from 'react'
import { ActionButton, Box, Token, Link } from '@revolut/ui-kit'
import { Link as LinkIcon, LinkExternal } from '@revolut/icons'
import { CellTypes, ColumnInterface, FilterType } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import { formatMoney } from '@src/utils/format'
import {
  BenefitsInterface,
  BenefitsInterfaceLegacy,
  BenefitStatus,
  BonusesInterface,
  RewardsSalary,
  RewardsSummary,
  SalarySacrificeInterface,
} from '@src/interfaces/rewards'
import { SalarySacrificeStatuses } from '@src/interfaces/employees'
import { CompensationBonusInterface } from '@src/interfaces/compensation'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import { ColorTagVariant } from '@src/components/ColorTag/ColorTag'

export const rewardsTypeColumn: ColumnInterface<RewardsSummary | RewardsSalary> = {
  type: CellTypes.text,
  idPoint: 'reward_type.id',
  dataPoint: 'reward_type.name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Component',
}

export const rewardsDescriptionColumn: ColumnInterface<RewardsSummary> = {
  type: CellTypes.text,
  idPoint: 'description',
  dataPoint: 'description',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Description',
}

export const rewardsAmountColumn: ColumnInterface<RewardsSummary> = {
  type: CellTypes.insert,
  idPoint: 'exchanged_total_amount',
  dataPoint: 'exchanged_total_amount',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) =>
    data.reward_type.id === 'benefits' ? (
      '-'
    ) : (
      <Box mr="s-8">{formatMoney(data.exchanged_total_amount, data.result_currency)}</Box>
    ),
  title: 'Amount',
  textAlign: 'right',
}

export const rewardsFromDate: ColumnInterface<RewardsSalary> = {
  type: CellTypes.date,
  idPoint: 'start_date',
  dataPoint: 'start_date',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'From date',
}

export const rewardsToDate: ColumnInterface<RewardsSalary> = {
  type: CellTypes.date,
  idPoint: 'end_date',
  dataPoint: 'end_date',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'To date',
}

export const rewardsSalaryAmountColumn: ColumnInterface<RewardsSalary> = {
  type: CellTypes.insert,
  idPoint: 'exchanged_amount',
  dataPoint: 'exchanged_amount',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => formatMoney(data.exchanged_amount, data.result_currency),
  title: 'Yearly salary',
}

export const salarySacrificeStatusColumn: ColumnInterface<SalarySacrificeInterface> = {
  type: CellTypes.text,
  idPoint: 'status',
  dataPoint: 'status.name',
  sortKey: 'status',
  filterKey: 'status',
  selectorsKey: selectorKeys.salary_sacrifice_status,
  title: 'Status',
  colors: data => {
    switch (data.status.id) {
      case SalarySacrificeStatuses.Active:
        return Token.color.green
      case SalarySacrificeStatuses.Pending:
        return Token.color.warning
      case SalarySacrificeStatuses.Expired:
        return Token.color.greyTone50
      default:
        return Token.color.foreground
    }
  },
}
export const salarySacrificeSchemaColumn: ColumnInterface<SalarySacrificeInterface> = {
  type: CellTypes.text,
  idPoint: 'salary_sacrifice_schema.id',
  dataPoint: 'salary_sacrifice_schema.name',
  sortKey: 'salary_sacrifice_schema',
  filterKey: 'salary_sacrifice_schema__id',
  selectorsKey: selectorKeys.salary_sacrifice_schemas,
  title: 'Salary sacrifice schema',
}

export const salarySacrificeStartAtColumn: ColumnInterface<SalarySacrificeInterface> = {
  type: CellTypes.date,
  idPoint: 'start_date',
  dataPoint: 'start_date',
  sortKey: 'start_date',
  filterKey: 'start_date',
  filterType: FilterType.date,
  selectorsKey: selectorKeys.none,
  title: 'Start Date',
}

export const salarySacrificeEndAtColumn: ColumnInterface<SalarySacrificeInterface> = {
  type: CellTypes.date,
  idPoint: 'end_date',
  dataPoint: 'end_date',
  sortKey: 'end_date',
  filterKey: 'end_date',
  filterType: FilterType.date,
  selectorsKey: selectorKeys.none,
  title: 'End Date',
}

export const salarySacrificeAmountColumn: ColumnInterface<SalarySacrificeInterface> = {
  type: CellTypes.insert,
  idPoint: 'sacrificed_amount',
  dataPoint: 'sacrificed_amount',
  sortKey: 'sacrificed_amount',
  filterKey: 'sacrificed_amount',
  filterType: FilterType.range,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) =>
    formatMoney(data.sacrificed_amount, data.sacrifice_currency.iso_code),
  title: 'Amount sacrificed',
}

export const salarySacrificeNumberColumn: ColumnInterface<SalarySacrificeInterface> = {
  type: CellTypes.text,
  idPoint: 'number_of_shares',
  dataPoint: 'number_of_shares',
  sortKey: 'number_of_shares',
  filterKey: 'number_of_shares',
  filterType: FilterType.range,
  selectorsKey: selectorKeys.none,
  title: 'Options equivalent',
}

export const benefitTypeColumn: ColumnInterface<BenefitsInterfaceLegacy> = {
  type: CellTypes.text,
  idPoint: 'type',
  dataPoint: 'type',
  sortKey: 'type',
  filterKey: 'type',
  selectorsKey: selectorKeys.benefit_types,
  title: 'Benefit',
}

export const benefitNameColumn: ColumnInterface<BenefitsInterface> = {
  type: CellTypes.text,
  idPoint: 'benefit_name',
  dataPoint: 'benefit_name',
  sortKey: 'benefit_name',
  filterKey: 'template_id',
  selectorsKey: selectorKeys.benefits_template_names,
  title: 'Benefit name',
}

export const benefitCategoryColumn: ColumnInterface<BenefitsInterface> = {
  type: CellTypes.text,
  idPoint: 'category.id',
  dataPoint: 'category.name',
  sortKey: 'category',
  filterKey: 'category',
  selectorsKey: selectorKeys.benefits_template_categories,
  title: 'Category',
}

export const benefitDescriptionColumn: ColumnInterface<
  BenefitsInterface | BenefitsInterfaceLegacy
> = {
  type: CellTypes.text,
  idPoint: 'description',
  dataPoint: 'description',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Description',
}

export const benefitConfluenceLinkColumn: ColumnInterface<BenefitsInterfaceLegacy> = {
  type: CellTypes.link,
  idPoint: 'confluence_link',
  dataPoint: 'confluence_link',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Confluence',
}

export const benefitBenefitLinkColumn: ColumnInterface<BenefitsInterfaceLegacy> = {
  type: CellTypes.insert,
  idPoint: 'benefit_link',
  dataPoint: 'benefit_link',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Link to benefit',
  insert: ({ data }) => {
    return data.benefit_link ? (
      <ActionButton size="xs" use="a" px="s-16" target="_blank" href={data.benefit_link}>
        Go to {data.provider}
      </ActionButton>
    ) : (
      '-'
    )
  },
}

export const benefitProviderColumn: ColumnInterface<BenefitsInterface> = {
  type: CellTypes.insert,
  idPoint: '',
  dataPoint: '',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Provider',
  insert: ({ data }) =>
    data.provider_url ? (
      <Link
        href={data.provider_url}
        target="_blank"
        rel="noreferrer noopener"
        onClick={e => e.stopPropagation()}
      >
        <LinkExternal />
      </Link>
    ) : (
      '-'
    ),
}

export const benefitProviderLegacyColumn: ColumnInterface<BenefitsInterfaceLegacy> = {
  type: CellTypes.text,
  idPoint: 'provider',
  dataPoint: 'provider',
  sortKey: 'provider',
  filterKey: 'provider',
  selectorsKey: selectorKeys.benefit_providers,
  title: 'Provider',
}

export const benefitPackageColumn: ColumnInterface<BenefitsInterface> = {
  type: CellTypes.text,
  idPoint: 'package',
  dataPoint: 'package',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Package',
}

export const benefitEnrolmentMethodColumn: ColumnInterface<BenefitsInterface> = {
  type: CellTypes.text,
  idPoint: 'enrolment_method',
  dataPoint: 'enrolment_method',
  sortKey: 'enrolment_method',
  filterKey: 'enrolment_method',
  selectorsKey: selectorKeys.benefits_package_enrolment_methods,
  title: 'Enrolment method',
}

export const benefitGuideColumn: ColumnInterface<BenefitsInterface> = {
  type: CellTypes.insert,
  idPoint: '',
  dataPoint: '',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Guide',
  insert: ({ data }) =>
    data.confluence_url ? (
      <Link
        href={data.confluence_url}
        target="_blank"
        rel="noreferrer noopener"
        onClick={e => e.stopPropagation()}
      >
        <LinkIcon size={20} />
      </Link>
    ) : (
      '-'
    ),
}

export const getBenefitStatusColor = (status: BenefitStatus) => {
  switch (status) {
    case 'enrolled':
      return Token.color.green
    case 'pending_approval':
    case 'pending_employee':
      return Token.color.warning
    case 'opted_out':
    case 'rejected':
      return Token.color.red
    default:
      return Token.color.foreground
  }
}

export const getBenefitStatusVariant = (status: BenefitStatus): ColorTagVariant => {
  switch (status) {
    case 'enrolled':
      return 'success'
    case 'pending_approval':
    case 'pending_employee':
      return 'warning'
    case 'opted_out':
    case 'rejected':
      return 'danger'
    default:
      return 'default'
  }
}

export const benefitStatusColumn: ColumnInterface<BenefitsInterface> = {
  type: CellTypes.text,
  idPoint: 'status.id',
  dataPoint: 'status.name',
  sortKey: 'status',
  filterKey: 'status',
  selectorsKey: selectorKeys.benefits_employee_enrolment_statuses,
  title: 'Status',
  colors: data => getBenefitStatusColor(data.status.id),
}

export const benefitChangedOnColumn: ColumnInterface<BenefitsInterface> = {
  type: CellTypes.dateTime,
  idPoint: 'changed_on',
  dataPoint: 'changed_on',
  sortKey: 'changed_on',
  filterKey: 'changed_on',
  selectorsKey: selectorKeys.none,
  filterType: FilterType.date,
  title: 'Changed on',
}

export const bonusDateColumn: ColumnInterface<
  BonusesInterface | CompensationBonusInterface
> = {
  type: CellTypes.date,
  idPoint: 'bonus_date',
  dataPoint: 'bonus_date',
  sortKey: 'bonus_date',
  filterKey: 'bonus_date',
  selectorsKey: selectorKeys.none,
  title: 'Date',
  headerTooltip: (
    <Box p="s-12" color="background" minWidth={250}>
      The date is the payment date if it's a cash bonus and vesting start date if it's an
      equity bonus
    </Box>
  ),
}

export const bonusPeriodColumn: ColumnInterface<
  BonusesInterface | CompensationBonusInterface
> = {
  type: CellTypes.text,
  idPoint: 'bonus_cycle',
  dataPoint: 'bonus_cycle',
  sortKey: null,
  filterKey: 'bonus_cycle',
  selectorsKey: selectorKeys.employee_bonus_cycles,
  title: 'Period',
}

export const bonusReasonColumn: ColumnInterface<
  BonusesInterface | CompensationBonusInterface
> = {
  type: CellTypes.text,
  idPoint: 'bonus_category.id',
  dataPoint: 'bonus_category.name',
  sortKey: 'bonus_category',
  filterKey: 'bonus_category',
  selectorsKey: selectorKeys.employee_bonus_categories,
  title: 'Reason',
}

export const bonusValueColumn: ColumnInterface<
  BonusesInterface | CompensationBonusInterface
> = {
  type: CellTypes.insert,
  idPoint: 'bonus_amount',
  dataPoint: 'bonus_amount',
  sortKey: 'bonus_amount',
  filterKey: 'bonus_amount',
  filterType: FilterType.range,
  selectorsKey: selectorKeys.none,
  title: 'Value',
  insert: ({ data }) =>
    formatMoney(
      data.bonus_amount,
      typeof data.bonus_currency === 'string'
        ? data.bonus_currency
        : data.bonus_currency.iso_code,
    ),
}

export const bonusStatusColumn: ColumnInterface<
  BonusesInterface | CompensationBonusInterface
> = {
  type: CellTypes.text,
  idPoint: 'status.id',
  dataPoint: 'status.name',
  sortKey: 'status',
  filterKey: null,
  title: 'Status',
  selectorsKey: selectorKeys.employee_bonus_status,
}

export const bonusTaxabilityColumn: ColumnInterface<
  BonusesInterface | CompensationBonusInterface
> = {
  type: CellTypes.text,
  idPoint: 'taxability.id',
  dataPoint: 'taxability.name',
  sortKey: 'taxability',
  filterKey: 'taxability',
  selectorsKey: selectorKeys.employee_bonus_taxabilities,
  title: 'Taxability',
}

export const bonusGrantTypeColumn: ColumnInterface<
  BonusesInterface | CompensationBonusInterface
> = {
  type: CellTypes.text,
  idPoint: 'bonus_type.id',
  dataPoint: 'bonus_type.name',
  sortKey: 'bonus_type',
  filterKey: 'bonus_type',
  selectorsKey: selectorKeys.employee_bonus_types,
  title: 'Bonus type',
}

export const bonusDetailsColumn: ColumnInterface<BonusesInterface> = {
  type: CellTypes.text,
  idPoint: 'bonus_details',
  dataPoint: 'bonus_details',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Details',
}

export const bonusCreatedOnColumn: ColumnInterface<CompensationBonusInterface> = {
  type: CellTypes.date,
  idPoint: 'creation_date_time',
  dataPoint: 'creation_date_time',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Created on',
}

export const bonusCreatedByColumn: ColumnInterface<CompensationBonusInterface> = {
  type: CellTypes.insert,
  idPoint: 'created_by.id',
  dataPoint: 'created_by.id',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.employee,
  title: 'Created by',
  insert: ({ data }) => <UserWithAvatar {...data.created_by} />,
}

export const bonusUpdatedOnColumn: ColumnInterface<CompensationBonusInterface> = {
  type: CellTypes.date,
  idPoint: 'update_date_time',
  dataPoint: 'update_date_time',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Last update on',
}

export const bonusUpdatedByColumn: ColumnInterface<CompensationBonusInterface> = {
  type: CellTypes.insert,
  idPoint: 'updated_by.id',
  dataPoint: 'updated_by.id',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.employee,
  title: 'Updated by',
  insert: ({ data }) => <UserWithAvatar {...data.updated_by} />,
}

export const actionsColumn: ColumnInterface<CompensationBonusInterface> = {
  type: CellTypes.insert,
  idPoint: 'id',
  dataPoint: 'id',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Actions',
}
