import { Flex, IconButton, Token } from '@revolut/ui-kit'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { selectorKeys } from '@src/constants/api'
import {
  roadmapDueDate,
  roadmapGenericNameColumn,
  roadmapPriorityColumn,
  roadmapProgressColumn,
  roadmapStatusColumn,
  roadmapStartDate,
  roadmapResolutionAtColumn,
  roadmapOwnerColumn,
  roadmapJiraLabelColumn,
  roadmapEpicUrlColumn,
} from '@src/constants/columns/roadmap'
import { TableNames } from '@src/constants/table'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { CellTypes, ColumnCellInterface } from '@src/interfaces/data'
import { GoalsInterface, GoalUpdateType } from '@src/interfaces/goals'
import { RoadmapInterface } from '@src/interfaces/roadmaps'
import React, { useState } from 'react'
import { isCascadedGoal, useSubmitFlowHelpers } from '../../../common/utils'
import { captureException } from '@sentry/react'
import { roadmapsRequests } from '@src/api/roadmaps'
import { useTableReturnType } from '@src/components/Table/hooks'
import { useGetRoadmapSettings } from '@src/api/settings'
import { IdAndName } from '@src/interfaces'
import Table from '@src/components/TableV2/Table'

export const RoadmapsList = ({
  viewMode,
  table,
  onSelected,
  updateType,
}: {
  viewMode: boolean
  table?: useTableReturnType<RoadmapInterface>
  onSelected?: (item: RoadmapInterface) => void
  updateType?: IdAndName<GoalUpdateType>
}) => {
  const { values } = useLapeContext<GoalsInterface>()
  const { data: roadmapSettings } = useGetRoadmapSettings()
  const [pendingDeleteId, setPendingDeleteId] = useState<number>()
  const { confirmationDialog, confirm, showError } = useSubmitFlowHelpers({
    confirmProps: { variant: 'compact' },
  })
  const isCascaded = isCascadedGoal(updateType)

  const actionsColumn = {
    type: CellTypes.insert,
    idPoint: 'actions_column',
    dataPoint: 'actions_column',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    textAlign: 'right',
    insert: ({ data }: { data: RoadmapInterface }) => {
      return (
        <Flex justifyContent="flex-end" alignItems="center" gap="s-12">
          {onSelected && !roadmapSettings?.jira_epics_enabled ? (
            <IconButton
              useIcon="Pencil"
              size={16}
              onClick={() => onSelected(data)}
              color={Token.color.greyTone50}
            />
          ) : null}
          <IconButton
            disabled={!!pendingDeleteId}
            onClick={async () => {
              try {
                const confirmed = await confirm({
                  body: `Are you sure you want to delete ${data.name} roadmap`,
                  yesBtnVariant: 'negative',
                  yesMessage: 'Delete',
                })

                if (confirmed.status === 'confirmed') {
                  setPendingDeleteId(data.id)
                  await roadmapsRequests.deleteItem(data.id)
                  values.roadmaps = values.roadmaps.filter(r => r.id !== data.id)
                }
              } catch (err) {
                captureException(err)
                showError('Failed to delete roadmap')
              } finally {
                setPendingDeleteId(undefined)
              }
            }}
            useIcon={pendingDeleteId === data.id ? 'Loading' : 'Delete'}
            size={16}
            color={Token.color.greyTone50}
          />
        </Flex>
      )
    },
    title: '',
    width: 40,
  }

  const labelColumn = roadmapSettings?.jira_epics_enabled
    ? {
        ...roadmapJiraLabelColumn,
        width: 80,
      }
    : null

  const epicUrlColumn = roadmapSettings?.jira_epics_enabled
    ? {
        ...roadmapEpicUrlColumn,
        width: 100,
      }
    : null

  const cells: ColumnCellInterface<RoadmapInterface>[] = viewMode
    ? [
        {
          ...roadmapGenericNameColumn,
          width: 200,
        },
        {
          ...roadmapPriorityColumn,
          width: 80,
        },
        {
          ...roadmapProgressColumn,
          width: 100,
        },
        {
          ...roadmapStatusColumn,
          width: 80,
        },
        {
          ...roadmapStartDate,
          width: 80,
        },
        {
          ...roadmapDueDate,
          width: 80,
        },
        {
          ...roadmapResolutionAtColumn,
          width: 80,
        },
        {
          ...roadmapOwnerColumn,
          width: 80,
        },
        labelColumn,
        epicUrlColumn,
        actionsColumn,
      ].filter(Boolean)
    : [
        {
          type: CellTypes.text,
          idPoint: 'review_cycle.id',
          dataPoint: 'review_cycle.name',
          sortKey: null,
          filterKey: null,
          selectorsKey: selectorKeys.none,
          title: 'Cycle',
          width: 100,
        },
        {
          ...roadmapGenericNameColumn,
          sortKey: null,
          filterKey: null,
          width: 200,
        },
        {
          ...roadmapPriorityColumn,
          sortKey: null,
          filterKey: null,
          width: 80,
        },
        {
          ...roadmapDueDate,
          sortKey: null,
          filterKey: null,
          width: 80,
        },
        labelColumn,
        actionsColumn,
      ].filter(Boolean)

  return values.roadmaps?.length ? (
    <Table.Widget>
      <Table.Widget.Table>
        <AdjustableTable<RoadmapInterface & { actions_column?: never }>
          hideCount
          name={TableNames.RoadmapsMain}
          {...(table || {})}
          data={values.roadmaps}
          count={values.roadmaps.length}
          dataType="Roadmap"
          row={{
            cells,
          }}
          hiddenCells={{
            actions_column: isCascaded,
          }}
        />
      </Table.Widget.Table>
      {confirmationDialog}
    </Table.Widget>
  ) : null
}
